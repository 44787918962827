import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Container,
    CssBaseline,
    Grid,
    Button,
} from '@mui/material';

import Header from '../../components/header';
import MainFeaturedPostWrapper from '../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../Home/components/mainFeaturedPost';
import ActionAreaImagesCard from '../../components/imagesCard/imagesCard';
import Reader from '../Reader';
import sections from '../../utils/menu';
import { AlertNotification } from '../../components/alert';
import { getFromSessionStorage } from '../../utils/session';
import BaseUrl from '../../utils/Api';
import StorieService from '../../services/storie';
import { AlertSettings, AlertSettingsProps } from '../../utils/interfaces/alert';
import CircularIndeterminate from '../../components/progress';
import defaultTheme from '../../utils/style';

interface ImagesPost {
    name: string;
    imagem: string;
}

interface DownloadRequest {
    storieId: number;
    userId: number;
    pages: string[];
}

const PagesCard: React.FC = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
    const [openReader, setOpenReader] = useState(false);
    const [imagesPosts, setImagesPosts] = useState<ImagesPost[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [selectedImageNames, setSelectedImageNames] = useState<string[]>([]);

    const handleImageClick = (index: number, event: React.MouseEvent) => {
        if (!(event.target as HTMLElement).closest('input[type="checkbox"]')) {
            setSelectedImageIndex(index);
            setOpenReader(true);
        }
    };

    const handleCloseReader = () => {
        setOpenReader(false);
    };

    const handleImageSelect = (index: number) => {
        const selectedImage = imagesPosts[index];
        const isSelected = selectedImageNames.includes(selectedImage.name);

        setSelectedImageNames(prevSelectedImageNames =>
            isSelected
                ? prevSelectedImageNames.filter(name => name !== selectedImage.name)
                : [...prevSelectedImageNames, selectedImage.name]
        );
    };

    const handleDownload = async () => {
        try {
            const storieId = getFromSessionStorage("storie") as number;
            setLoading(true);

            const downloadRequest: DownloadRequest = {
                storieId,
                userId: 0,
                pages: selectedImageNames
            };

            const response = await StorieService.download(downloadRequest);
            const downloadPath = response.data.path;
            const downloadLink = `${BaseUrl}${downloadPath}`;

            const a = document.createElement('a');
            a.href = downloadLink;
            a.download = downloadPath.split('/').pop() || 'download.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
            setOpenAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const getStorieAsync = async () => {
        try {
            const storieId = getFromSessionStorage("storie");
            const response = await StorieService.read(storieId as number);

            const storiePages = response.data.pages?.map((page: any) => ({
                name: page.pageName,
                imagem: page.pagePath
            })) || [];

            setImagesPosts(storiePages);
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
            setOpenAlert(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStorieAsync();
    }, []);

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sections} />
                    <MainFeaturedPostWrapper post={mainFeaturedPost} />
                    <Container sx={{ py: 2 }} maxWidth="md">
                        <Grid container spacing={4}>
                            {imagesPosts.map((post, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <ActionAreaImagesCard
                                        key={post.name}
                                        imageLink={`${BaseUrl}${post.imagem}`}
                                        title={post.name}
                                        onClick={(event) => handleImageClick(index, event)}
                                        isSelected={selectedImageNames.includes(post.name)}
                                        onSelect={() => handleImageSelect(index)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}
                            disabled={imagesPosts.length === 0}
                        >
                            {selectedImageNames.length === 0
                                ? 'Download Story'
                                : 'Download Selected Images'}
                        </Button>
                    </Box>
                    {openReader && (
                        <Reader
                            images={imagesPosts.map(post => `${BaseUrl}${post.imagem}`)}
                            initialIndex={selectedImageIndex}
                            onClose={handleCloseReader}
                        />
                    )}
                </Container>
            </ThemeProvider>
        </>
    );
};

export default PagesCard;
