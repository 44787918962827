import instance from '..';

const get = () => {
    return instance.get(`/authenticate`);
}

const validate = (code: string) => {
    return instance.get(`/authenticate/validate?token=${code}`);
}

const AuthenticateService = {
    get,
    validate,
};

export default AuthenticateService;