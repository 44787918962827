const convertImageToBase64 = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const value = reader.result as string;
            const base64Data = value.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
            resolve(base64Data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(image);
    });
};

export default convertImageToBase64;