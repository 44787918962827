import axios, { AxiosError } from 'axios';

export interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

export class AlertSettings implements AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';

    constructor(message: string, type: 'error' | 'success' | 'info' | 'warning') {
        this.message = message;
        this.type = type;
    }

    static fromError(error: any): AlertSettings {
        let message = 'An unexpected error occurred';
        let type: 'error' | 'warning' = 'error'; // Default is error

        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 500;
            const errorData = error.response?.data;

            // Usando o campo 'Message' do payload, se existir
            if (errorData && errorData.Message) {
                message = errorData.Message; // Mensagem do payload
            } else {
                message = error.response?.data?.message || error.message || message;
            }

            if (statusCode >= 500) {
                type = 'error'; // Erro do servidor
            } else if (statusCode >= 400 && statusCode < 500) {
                type = 'warning'; // Erro do cliente
            }
        } else if (error instanceof Error) {
            message = error.message;
        }

        return new AlertSettings(message, type);
    }
}
