import instance from '../..';

export interface Tiers {
    title: string;
    cost: number;
    removed: boolean;
    users: string[] | null;
    stories: string[] | null;
    id: number;
}

const get = () => {
    return instance.get(`/tier/tiers`);
}

const TierService = {
    get,
};

export default TierService;