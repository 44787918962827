import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Checkbox, FormControl, Grid, InputLabel, LinearProgress, Modal } from '@mui/material';
import CircularIndeterminate from '../../../../components/progress';
import Header from '../../../../components/header';
import MainFeaturedPostWrapper from '../../../Home/components/MainFeaturedPostWrapper';
import sectionsAdmin from '../../../../utils/menu/admin';
import mainFeaturedPost from '../../../Home/components/mainFeaturedPost';
import StorieService from '../../../../services/storie';
import ActionAreaCard from '../../../../components/card';
import BaseUrl from '../../../../utils/Api';
import { formatDateToMMDDYYYY } from '../../../../utils/format/date';
import StoryService from '../../../../services/admin/storie';
import { useDropzone } from 'react-dropzone';
import convertImageToBase64 from '../../../../utils/actions/converter';
import { AlertSettings, AlertSettingsProps } from '../../../../utils/interfaces/alert';
import { AlertNotification } from '../../../../components/alert';
import defaultTheme from '../../../../utils/style';

interface ImagesPost {
    name: string;
    imagem: string;
}

interface FeaturePost {
    storieId: number;
    name: string;
    cover: string;
    lastUpdate: string;
    views: number;
    tierTitle: string;
}

interface StorieBody {
    storieId: number;
    name: string;
    file: string;
    update: boolean;
}

interface FormData {
    images: Array<File>;
}

interface StorieBodyRemove {
    storieId: number;
    name: string;
}

const UploadPages: React.FC = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [imagesPosts, setImagesPosts] = useState<ImagesPost[]>([]);
    const [featuredPosts, setFeaturedPost] = useState<FeaturePost[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedStorieId, setSelectedStorieId] = useState<number | null>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [newPages, setCheckNewPages] = useState(false);
    const [formData, setFormData] = useState<FormData>({ images: [] });
    const [modalOpen, setModalOpen] = useState(false);
    const onDrop = (acceptedFiles: Array<File>) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: acceptedFiles,
        }));
    };
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [imagemName, setImagemName] = useState<string>();
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const getStoriesAsync = async () => {
        try {
            const response = await StorieService.stories();
            setFeaturedPost(response.data);
        } catch (error) {
            setAlertSettings({ message: 'Error fetching stories', type: 'error' });
            setOpenAlert(true);
        }
        setLoading(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            setFormData((prevState) => ({
                images: [...prevState.images, ...selectedFiles]
            }));
        }
    };

    const handleAlbumClick = async (storieId: number) => {
        try {
            setSelectedStorieId(storieId);
            const response = await StoryService.pages(storieId);
            const storiePages = response.data.pages?.map((page: any) => ({
                name: page.pageName,
                imagem: page.pagePath
            })) || [];
            setImagesPosts(storiePages);
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
            setOpenAlert(true);
        }
    };

    const handleRemoveImage = async (imageName: string) => {
        const payload: StorieBodyRemove = {
            name: imageName,
            storieId: selectedStorieId as number
        };
        await StoryService.delete_body(payload);
    };

    const handleRemoveImageFromUpload = (index: number) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ images: updatedImages });
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleUpload = async () => {
        try {
            for (const image of formData.images) {
                const base64Data = await convertImageToBase64(image);
                const payload: StorieBody = {
                    storieId: selectedStorieId as number,
                    file: base64Data,
                    name: image.name,
                    update: newPages
                };

                await StoryService.post_body(payload, {
                    responseType: 'blob',
                    onUploadProgress: (event) => {
                        setImagemName(payload.name);
                        setUploadProgress((event.loaded / (event.total ?? 0)) * 100);
                    },
                });
            }

            setAlertSettings({
                message: `The Pages have been saved.`,
                type: 'success'
            });
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
        }
        setOpenAlert(true);
        setModalOpen(false);
    };

    useEffect(() => {
        getStoriesAsync();
    }, []);

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Container sx={{ py: 2 }} maxWidth="md">
                            <Box sx={{ bgcolor: 'background.paper', pt: 2, pb: 2 }}>
                                <Grid container spacing={4}>
                                    {featuredPosts.map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <ActionAreaCard
                                                imageLink={`${BaseUrl}${post.cover}?${new Date().getTime()}`}
                                                title={post.name}
                                                tierTitle={post.tierTitle}
                                                lastUpdateDate={formatDateToMMDDYYYY(post.lastUpdate)}
                                                viewCount={post.views}
                                                onClick={() => handleAlbumClick(post.storieId)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>

                            {/* Botão aparece apenas se uma storie for selecionada */}
                            {selectedStorieId && (
                                <FormControl fullWidth sx={{ my: 4 }}>
                                    <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" sx={{ mb: 2 }} >
                                        Select the Pages to Upload
                                    </Button>
                                    <Grid container spacing={3}>
                                        {imagesPosts.map((page, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                                <Box
                                                    textAlign="center"
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    sx={{
                                                        p: 2,
                                                        bgcolor: 'background.paper',
                                                        borderRadius: '8px',
                                                        boxShadow: 1,
                                                    }}
                                                >
                                                    <img
                                                        src={`${BaseUrl}${page.imagem}`}
                                                        alt={page.name}
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '300px',
                                                            borderRadius: '8px',
                                                            marginBottom: '10px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        size="small"
                                                        onClick={() => handleRemoveImage(page.name)}
                                                    >
                                                        Remove Image
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            )}

                            {/* Modal para exibir as imagens */}
                            <Modal open={modalOpen} onClose={handleCloseModal}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%',
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        borderRadius: 2,
                                        maxHeight: '80vh',
                                        overflowY: 'auto'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Upload Pages:
                                    </Typography>
                                    <FormControl fullWidth sx={{ my: 2 }}>
                                        <InputLabel htmlFor="newPages">New Pages</InputLabel>
                                        <Checkbox
                                            id="newPages"
                                            checked={newPages}
                                            onChange={(e) => setCheckNewPages(e.target.checked)}
                                        />
                                    </FormControl>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <Button variant="contained" color="primary" >
                                                {'Select the Pages'}
                                            </Button>
                                        </FormControl>
                                    </div>
                                    <Grid container spacing={2}>
                                        {formData.images.map((file, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                                <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
                                                    <InputLabel htmlFor="newPages">{file.name}</InputLabel>
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        style={{
                                                            maxWidth: '300px',
                                                            maxHeight: '300px',
                                                            borderRadius: '8px',
                                                            marginBottom: '10px',
                                                        }}
                                                    />
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => handleRemoveImageFromUpload(index)}>
                                                        Remove Image
                                                    </Button>
                                                </Box>

                                            </Grid>
                                        ))}
                                    </Grid>
                                    {uploadProgress > 0 && ( // Mostra a barra de progresso apenas se houver progresso
                                        <div>
                                            <h4>Upload Progress:</h4>
                                            <LinearProgress variant="determinate" value={uploadProgress} />
                                            <Typography variant="body2" color="text.secondary">
                                                File: {imagemName} - {uploadProgress.toFixed(2)}% Uploaded
                                            </Typography>
                                        </div>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
                                        <Button variant="contained" color="primary" onClick={handleUpload}>
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleCloseModal}
                                        >
                                            Close
                                        </Button>
                                    </Box>

                                </Box>
                            </Modal>
                        </Container>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default UploadPages;
