import instance from '..';

const removeBan = async (userId: number) => {
    return await instance.put(`/user/removeban-user?userId=${userId}`);
};

const ban = async (userId: number) => {
    return await instance.put(`/user/ban-user?userId=${userId}`);
};

const create = async (payload: any) => {
    return await instance.post(`/user`, payload);
}

const get = async () => {
    return await instance.get(`/user`);
}

const UserService = {
    get,
    removeBan,
    ban,
    create,
};

export default UserService;