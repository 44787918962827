import React, { useState } from 'react';
import { Button, FormControl, InputLabel, OutlinedInput, Box, Typography, createTheme, ThemeProvider } from '@mui/material';
import { Container, CssBaseline } from '@mui/material';
import TokenService from '../../services/token'; // Importe seu serviço aqui
import { AlertNotification } from '../../components/alert'; // Importe o componente de alerta
import { useNavigate } from 'react-router-dom';
import { AlertSettings, AlertSettingsProps } from '../../utils/interfaces/alert';
import CircularIndeterminate from '../../components/progress';
import Header from '../../components/header';
import MainFeaturedPostWrapper from '../Home/components/MainFeaturedPostWrapper';
import sections from '../../utils/menu';
import mainFeaturedPost from '../Home/components/mainFeaturedPost';
import SubscriberService from '../../services/subscriber';
import defaultTheme from '../../utils/style';

const TokenAuthenticate: React.FC = () => {
    const [keyValue, setKeyValue] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    const handleSend = async () => {
        setLoading(true);
        try {
            const response = await TokenService.validate(keyValue);
            const invalid = Boolean(response.data);

            if (invalid) {
                setAlertSettings({
                    message: 'The token has already expired.',
                    type: 'error'
                });
            }
            else {
                setAlertSettings({
                    message: 'Key sent successfully!',
                    type: 'success'
                });

                await SubscriberService.post(keyValue);
                navigate("/Storie");
            }
        } catch (error) {
            const alert = AlertSettings.fromError(error);

            setAlertSettings(alert);
            setOpenAlert(true);
        } finally {
            setLoading(false);
            setOpenAlert(true);
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert}
            />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sections} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'background.paper',
                                pt: 4,
                                pb: 4,
                            }}
                        >
                            <Container sx={{ py: 2 }} maxWidth="md">
                                <Typography variant="h4" gutterBottom textAlign="center">
                                    Enter your Key
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <FormControl
                                        variant="outlined"
                                        sx={{
                                            width: '800px',
                                        }}
                                    >
                                        <InputLabel htmlFor="key">Key</InputLabel>
                                        <OutlinedInput
                                            id="key"
                                            value={keyValue}
                                            onChange={(e) => setKeyValue(e.target.value)}
                                            label="Key"
                                        />
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSend}
                                        disabled={loading}
                                        sx={{ width: '300px' }}
                                    >
                                        Send
                                    </Button>
                                </Box>
                            </Container>
                        </Box>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default TokenAuthenticate;
