import React, { useEffect, useState } from 'react';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import AdminService from '../../services/admin';
import { AlertSettings, AlertSettingsProps } from '../../utils/interfaces/alert';
import CookieFunctions from '../../utils/actions/cookies';

const Admin: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [redirectAfterAlert, setRedirectAfterAlert] = useState(false); // Nova flag

    const GetAuthenticateToken = async (code: string) => {
        try {
            setLoading(true);
            await AdminService.get(code);

            navigate("/Create");
        } catch (error) {
            const alert = AlertSettings.fromError(error);

            setAlertSettings(alert);
            setOpenAlert(true);
            setRedirectAfterAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const GetScopeAndRegister = async () => {
        try {
            setLoading(true);

            const response = await AdminService.scoped();
            const redirectUrl = response.data;

            if (redirectUrl) {
                window.location.href = redirectUrl; 
            }
        } catch (error) {
            setAlertSettings({
                message: 'Error getting authentication token',
                type: 'error'
            });
            setOpenAlert(true);
            setRedirectAfterAlert(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(location.search);
            const codeParam = searchParams.get('code');
            if (codeParam) {
                await GetAuthenticateToken(codeParam);
            } else {
                await GetScopeAndRegister();
            }
        };

        fetchData();
    }, [location.search]);

    // Fecha o alerta e faz o redirecionamento somente se estiver habilitado
    const handleAlertClose = () => {
        setOpenAlert(false);
        if (redirectAfterAlert) {
            navigate("/Storie");
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={handleAlertClose} />
        </>
    );
};

export default Admin;
