import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
    palette: {
      mode: 'dark', // Define o modo como dark
      primary: {
        main: '#90caf9', // Azul claro (ajuste conforme necessário)
      },
      secondary: {
        main: '#f48fb1', // Rosa claro (ajuste conforme necessário)
      },
      background: {
        default: '#121212', // Fundo padrão dark
        paper: '#1e1e1e', // Fundo dos componentes
      },
    },
  });

export default defaultTheme;