import instance from '..';

const scoped = () => {
    return instance.get(`/admin/authorization`);
}

const get = async (hash: string) => {
    return await instance.get(`/admin?code=${hash}`);
};

const AdminService = {
    scoped,
    get,
};

export default AdminService;