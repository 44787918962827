import { AxiosRequestConfig } from 'axios';
import instance from '../..';

const post_head = (payload: any) => {
    return instance.post(`/storie/storie-head`, payload);
}

const post_body = (payload: any, config?: AxiosRequestConfig<any>) => {
    return instance.post(`/storie/storie-body`, payload, { ...config });
}

const delete_body = (payload: any) => {
    return instance.post(`/storie/storie-body-delete`, payload);
}

const pages = (storieId: number) => {
    return instance.get(`/storie?storieId=${storieId}`);
}

const StoryService = {
    post_head,
    post_body,
    delete_body,
    pages
};

export default StoryService;