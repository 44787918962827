import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, OutlinedInput, Box, Typography, ThemeProvider, CssBaseline, Container } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Header from '../../../../components/header';
import sectionsAdmin from '../../../../utils/menu/admin';
import mainFeaturedPost from '../../../Home/components/mainFeaturedPost';
import MainFeaturedPostWrapper from '../../../Home/components/MainFeaturedPostWrapper';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CircularIndeterminate from '../../../../components/progress';
import { AlertSettings, AlertSettingsProps } from '../../../../utils/interfaces/alert';
import { AlertNotification } from '../../../../components/alert';
import UserService from '../../../../services/user';
import defaultTheme from '../../../../utils/style';

export interface User {
    userId: number;
    name: string;
    tier: string;
    banned: boolean;
}

const UserBan: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await UserService.get();
            const userList = response.data || [];
            const formattedUsers = userList.map((user: User) => ({
                id: user.userId,
                userId: user.userId,
                name: user.name,
                tier: user.tier,
                banned: user.banned
            }));
            setUsers(formattedUsers);
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
            setOpenAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const handleBanUser = async (userId: number) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.userId === userId ? { ...user, banned: true } : user
            )
        );
        setAlertSettings({ message: `User ${userId} has been banned.`, type: 'info' });
        setOpenAlert(true);
    };

    const handleUnbanUser = async (userId: number) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.userId === userId ? { ...user, banned: false } : user
            )
        );
        setAlertSettings({ message: `User ${userId} has been unbanned.`, type: 'info' });
        setOpenAlert(true);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = users.filter(user =>
        user?.userId?.toString()?.startsWith(searchTerm)
    );

    const columns: GridColDef[] = [
        { field: 'userId', headerName: 'User ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'tier', headerName: 'Tier', width: 250 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>
                    {params.row.banned ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUnbanUser(params.row.userId)}
                        >
                            Unban
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleBanUser(params.row.userId)}
                        >
                            Ban
                        </Button>
                    )}
                </>
            ),
        }
    ];

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="User Management" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: 'background.paper', pt: 4, pb: 4 }}>
                            <Container sx={{ py: 2 }} maxWidth="md">
                                <Typography variant="h4" gutterBottom textAlign="center">
                                    Ban/Unban Users
                                </Typography>
                                <FormControl variant="outlined" sx={{ width: '300px', marginBottom: 2 }}>
                                    <InputLabel htmlFor="searchTerm">User ID</InputLabel>
                                    <OutlinedInput
                                        id="searchTerm"
                                        name="searchTerm"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        label="User ID"
                                    />
                                </FormControl>
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={filteredUsers}
                                        columns={columns}
                                        getRowId={(row) => row.userId}
                                        pageSizeOptions={[5, 10, 20]}
                                        loading={loading}
                                    />
                                </div>
                            </Container>
                        </Box>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default UserBan;
