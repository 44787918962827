import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, OutlinedInput, Box, Typography, Select, MenuItem, ThemeProvider, CssBaseline, Container } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import sections from '../../../utils/menu';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import TierService, { Tiers } from '../../../services/admin/tier';
import { AlertSettings, AlertSettingsProps } from '../../../utils/interfaces/alert';
import UserService from '../../../services/user';
import sectionsAdmin from '../../../utils/menu/admin';
import defaultTheme from '../../../utils/style';

export interface User {
    userId: number;
    name: string;
    email: string;
    tierId: number;
    active: boolean;
}

const UserRegister: React.FC = () => {
    const [formData, setFormData] = useState<User>({
        userId: 0,
        name: "",
        email: "",
        tierId: 0,
        active: false
    });

    const [tiers, setTiers] = useState<Tiers[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    useEffect(() => {
        const fetchTiers = async () => {
            setLoading(true);
            try {
                const response = await TierService.get();
                const activeTiers = response.data.filter((tier: Tiers) => !tier.removed);
                setTiers(activeTiers);
            } catch (error) {
                setAlertSettings(AlertSettings.fromError(error));
                setOpenAlert(true);
            } finally {
                setLoading(false);
            }
        };

        fetchTiers();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target as HTMLInputElement;
        setFormData({
            ...formData,
            [name as string]: name === 'userId' ? Number(value) : Number(value),
        });
    };

    const handleSend = async () => {
        setLoading(true);
        try {
            formData.active = true;
            const response = await UserService.create(formData);
            setAlertSettings({ message: response.data, type: 'info' });
        } catch (error) {
            setAlertSettings(AlertSettings.fromError(error));
        } finally {
            setLoading(false);
            setOpenAlert(true);
        }
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="User Registration" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'background.paper',
                                pt: 4,
                                pb: 4,
                            }}
                        >
                            <Container sx={{ py: 2 }} maxWidth="md">
                                <Typography variant="h4" gutterBottom textAlign="center">
                                    Register a User
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    {/* User ID Field */}
                                    <FormControl variant="outlined" sx={{ width: '800px' }}>
                                        <InputLabel htmlFor="userId">User ID</InputLabel>
                                        <OutlinedInput
                                            id="userId"
                                            name="userId"
                                            type="number"
                                            value={formData.userId}
                                            onChange={handleChange}
                                            label="User ID"
                                        />
                                    </FormControl>

                                    {/* Tier Dropdown */}
                                    <FormControl variant="outlined" sx={{ width: '800px' }}>
                                        <InputLabel htmlFor="tierId">Tier</InputLabel>
                                        <Select
                                            id="tierId"
                                            name="tierId"
                                            value={formData.tierId}
                                            onChange={(e) => handleChange(e as any)}
                                            label="Tier"
                                        >
                                            <MenuItem value={0}>Select a Tier</MenuItem>
                                            {tiers.map((tier) => (
                                                <MenuItem key={tier.id} value={tier.id}>
                                                    {`${tier.title} - $${tier.cost}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {/* Submit Button */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSend}
                                        disabled={loading}
                                        sx={{ width: '300px' }}
                                    >
                                        Register
                                    </Button>
                                </Box>
                            </Container>
                        </Box>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default UserRegister;
